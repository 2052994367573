import * as React from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Page from './Page'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Duomenų šaltinis '}
      <Link color="inherit" href="https://transparency.entsoe.eu/">
        ENTSO-E
      </Link>
    </Typography>
  )
}

export default function App() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Page />
        <Copyright />
      </Box>
    </Container>
  )
}
