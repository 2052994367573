import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableSortLabel from '@mui/material/TableSortLabel'

import TextField from '@mui/material/TextField'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Divider from '@mui/material/Divider'

const BASE_URL = false ? 'http://localhost:5000' : 'https://elektroskainos.lt'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

function sortAsc(items) {
  return items.prices.sort(function (a, b) {
    if (a.price < b.price) {
      return -1
    }
    if (a.price > b.price) {
      return 1
    }
    return 0
  })
}

function sortDesc(items) {
  return items.prices.sort(function (a, b) {
    if (a.price < b.price) {
      return 1
    }
    if (a.price > b.price) {
      return -1
    }
    return 0
  })
}

function sortDiffAsc(items) {
  return items.prices.sort(function (a, b) {
    if (a.difference < b.difference) {
      return -1
    }
    if (a.difference > b.difference) {
      return 1
    }
    return 0
  })
}

function sortDiffDesc(items) {
  return items.prices.sort(function (a, b) {
    if (a.difference < b.difference) {
      return 1
    }
    if (a.difference > b.difference) {
      return -1
    }
    return 0
  })
}

function init(initialCount) {
  return { prices: initialCount }
}

function reducer(state, action) {
  switch (action.type) {
    case 'asc':
      return { prices: sortAsc(state), order: 'asc' }
    case 'desc':
      return { prices: sortDesc(state), order: 'desc' }
    case 'asc_diff':
      return { prices: sortDiffAsc(state), diff_order: 'asc_diff' }
    case 'desc_diff':
      return { prices: sortDiffDesc(state), diff_order: 'desc_diff' }
    case 'init':
      return init(action.payload)
    default:
      throw new Error()
  }
}

export default function Page() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [state, dispatch] = useReducer(reducer, [], init)
  const [dateValue, setDateValue] = useState(moment())
  const [modalData, setOpenModal] = useState({ open: false, countryId: null })
  const [avgLtPriceData, setAvgLtPriceData] = useState({})
  const [todayLtPriceData, setTodayLtPriceData] = useState({})

  const handleOpen = (countryId) =>
    setOpenModal({ open: false /* add back true */, countryId })
  const handleClose = () => setOpenModal({ open: false, countryId: null })

  const fetchData = async (newDate) => {
    const formattedDate = newDate.format('YYYY-MM-DD')
    setLoading(true)
    try {
      const { data: response } = await axios.get(
        `${BASE_URL}/api/prices/${formattedDate}`,
      )
      setData(response)
      dispatch({ type: 'init', payload: response })
    } catch (error) {
      console.error(error.message)
    }
    setLoading(false)
  }

  const fetchAvgLtPrice = async (newDate) => {
    try {
      const link = `${BASE_URL}/api/estimated_price_for_lt`
      const { data: response } = await axios.get(link)
      setAvgLtPriceData(response)
    } catch (error) {
      console.error(error.message)
    }
    setLoading(false)
  }

  const fetchTodayLtPrice = async () => {
    try {
      const link = `${BASE_URL}/api/price/LT/today`
      const { data: response } = await axios.get(link)
      setTodayLtPriceData(response)
    } catch (error) {
      console.error(error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData(moment())
    fetchAvgLtPrice()
    fetchTodayLtPrice()
  }, [])

  const diffText = (diff) => {
    if (diff < 0) {
      return <Typography color="green">{diff}%</Typography>
    } else {
      return <Typography color="red">{diff}%</Typography>
    }
  }

  const CurrentMonthPriceInLTCard = () => {
    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            Prognozuojama elektros kaina biržoje Lietuvoje šį mėnesį
          </Typography>
          <Typography sx={{ fontSize: 22 }} variant="body">
            {avgLtPriceData.price} €/kWh.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  const TodayPriceInLTCard = () => {
    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            Šiandien elektros kaina biržoje Lietuvoje
          </Typography>
          <Typography sx={{ fontSize: 22 }} variant="body">
            {todayLtPriceData.price} €/kWh.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Container disableGutters>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          {CurrentMonthPriceInLTCard()}
        </Grid>
        <Grid item xs={6}>
          {TodayPriceInLTCard()}
        </Grid>
      </Grid>

      <Container disableGutters>
        <Typography variant="h5" component="h1" gutterBottom pt="20px">
          Elektros kaina Europos biržose
        </Typography>
        <Modal
          open={modalData.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Country id {modalData.countryId}
            </Typography>
            <Button onClick={handleClose}>Uždaryti</Button>
          </Box>
        </Modal>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box pt="20px">
            <DatePicker
              label="Pasirinkite dieną"
              value={dateValue}
              pt={10}
              minDate={moment('08/30/2022').toDate()}
              maxDate={moment().add('1', 'day').toDate()}
              onChange={(newValue) => {
                setDateValue(newValue)
                fetchData(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Šalis</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">
                    <TableSortLabel
                      active={true}
                      direction={state.order === 'asc' ? 'asc' : 'desc'}
                      onClick={() =>
                        dispatch({
                          type: state.order === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      Kaina €/kWh
                    </TableSortLabel>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">
                    <TableSortLabel
                      active={true}
                      direction={
                        state.diff_order === 'asc_diff' ? 'asc' : 'desc'
                      }
                      onClick={() =>
                        dispatch({
                          type:
                            state.diff_order === 'asc_diff'
                              ? 'desc_diff'
                              : 'asc_diff',
                        })
                      }
                    >
                      Pokytis nuo vakar
                    </TableSortLabel>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <div>Loading</div>}

              {data.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleOpen(item.id)}
                  >
                    <Typography>{item.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{item.price}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {diffText(item.difference)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Container>
  )
}
